import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const szemuveg = () => (
  <Layout>
    <SEO title="SZEMÜVEG KÉSZÍTÉS, -JAVÍTÁS, -KARBANTARTÁS
" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
    



<div className="left2 mt-20 "><h3 className="maintext2 left2 ">Szemüvegét rövid határidővel elkészítjük, megjavítjuk.
</h3>
<h3 className="maintext2 mt-2 left2">
Eltört szemüveg forrasztását is vállaljuk, illetve alkatrész pótlásokat pl: orrnyereg-tartó, csavar, szárvég, damil, szár.</h3>

<h3 className="maintext2 mt-2 left2">
Díjmentesen beállítjuk és megtisztítjuk szemüvegét (ultrahangos tisztítóval), itt készült szemüvegek esetén.</h3>












</div></div>

    </div></div>
  </Layout>
)

export default szemuveg
